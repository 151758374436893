<template>
  <div class="wrapper">
    <edit-temp :headers="headers" :upload-url="uploadUrl" :show-name="true"
               ref="editTemp"
               @saveImage="httpRequest"/>
  </div>
</template>

<script>
import editTemp from './EditTemp.vue'

export default {
  components: {editTemp},
  props: {},
  data() {
    return {
      uploadUrl: "admin/upload/uploadImage",
      headers: {
        adClientType: 'pc_console',
        Authentication: this.$store.state.account.token,
      },
      diyModel: {}
    }
  },
  computed: {},
  watch: {
    "$route"() {
      if (this.$route.query.id > 0 && this.$route.query.id != this.diyModel.id) {
        this.getModelInfo(this.$route.query.id)
      }
    }
  },
  created() {
    if (this.$route.query.id > 0) {
      this.getModelInfo(this.$route.query.id)
    }
  },
  mounted() {

  },
  methods: {
    getModelInfo(id) {
      this.$post("diy/query", {id: id}).then(res => {
        this.diyModel = res.data || {}
        this.$refs.editTemp.reloadModel(this.diyModel)
      })
    },
    // 自定义上传图片
    httpRequest(data) {
      const {file, fabricJson, name, classify} = data
      const fd = new FormData()// FormData 对象
      fd.append('file', file)// 文件对象
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          adClientType: 'pc_console',
          Authentication: this.$store.state.account.token,
        }
      }
      this.$upload("upload/uploadImage", fd, config).then(res => { // 上传生成的图片
        this.saveTemplate(JSON.stringify(fabricJson), name, res.data, classify)
      })
    },
    // 保存模板
    saveTemplate(content, modelName, thumbPic, classify) {
      this.$postJson("diy/save", {
        id: this.diyModel.id,
        content,
        modelName,
        thumbPic,
        classify
      }).then(res => {
        this.$message.success('保存成功！')
        this.$router.push({path: '/diy/template'})
      })
    }
  }
}
</script>
<style lang="less" scoped>
.wrapper {
}
</style>
